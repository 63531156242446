import { ImageSourceHelper } from '@sortlist-frontend/media';
import clsx from 'clsx';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { useSocialLinks } from '_core/hooks/use-social-links';

import * as S from '../styles';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

const facebookIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/facebook.svg`);
const instagramIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/instagram.svg`);
const linkedinIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/linkedin.svg`);
const twitterIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/twitter.svg`);
const youtubeIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/youtube.svg`);

export const Socials = () => {
  const { getUrl: getSocialUrl } = useSocialLinks();
  const socials = [
    { alt: 'Twitter', href: getSocialUrl('twitter'), src: twitterIcon },
    { alt: 'Facebook', href: getSocialUrl('facebook'), src: facebookIcon },
    { alt: 'Linkedin', href: getSocialUrl('linkedin'), src: linkedinIcon },
    { alt: 'Instagram', href: getSocialUrl('instagram'), src: instagramIcon },
    { alt: 'YouTube', href: getSocialUrl('youtube'), src: youtubeIcon },
  ];
  return (
    <div className="layout-row my-32">
      {socials.map((social, index) => {
        return (
          <Obfuscate
            href={social.href}
            className={clsx(
              { 'mr-8': index + 1 !== socials.length },
              'p-8 bg-secondary-300 rounded layout-column layout-align-center-center',
            )}
            key={social.alt}
            obfuscate={true}>
            <S.SocialIcon src={social.src} alt={social.alt} width={12} height={12} />
          </Obfuscate>
        );
      })}
    </div>
  );
};
