import { urlParse } from '@sortlist-frontend/utils';

import { ConfigSocialLinks, ConfigSocialLinksKeys, linksConfigFactory } from '_config/site.links.config';

type GetUrlQueryParams = {
  ref?: string;
} & Record<string, string>;

export const useSocialLinks = () => {
  const linksMap = linksConfigFactory({}).social;
  return {
    getUrl: <T extends ConfigSocialLinksKeys>(
      key: T,
      queryParams?: GetUrlQueryParams,
      params?: ConfigSocialLinks[T] extends (...args: infer P) => string ? P[0] : never,
    ): string => {
      type LinkFunction = (props: unknown) => string;

      const val = linksMap[key];
      const link = (typeof val === 'function' ? (val as LinkFunction)(params) : val) as string;

      if (queryParams !== undefined) {
        const parsed = urlParse(link as string, true);
        parsed.set('query', { ...(parsed.query || {}), ...queryParams });
        return parsed.toString();
      }
      return link as string;
    },
  };
};
