import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { DomainInfo } from '_backend/integration/domain-info';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { getExternalResourcesUrl } from '_components/layout/utils';
import { usePublicAppContext } from '_core/context/public-app-context';

import * as S from './styles';

type CustomLink = {
  id: string;
  label: string;
  href: string;
  class?: string;
};

export const Menus = () => {
  const { t } = useTranslation(['common']);
  const { domainInfo, locale, briefingOptions, getUrl } = usePublicAppContext();
  const pathUrl = domainInfo?.getLocalizedPath(locale);

  const menus = [
    {
      label: t('common:footer.navigation.clients.label'),
      id: 'clients',
      links: [
        {
          id: 'clientsProjectCTA',
          label: t('common:footer.navigation.clients.new'),
          href: getUrl('project.new', { autoload: 'true' }),
        },
        { id: 'explore', label: t('common:footer.navigation.clients.explore'), href: `${pathUrl}/explore` },
        {
          id: 'getadvice',
          label: t('common:footer.navigation.clients.getAdvice'),
          href: `${pathUrl}/clients/products/advisors`,
        },
        { id: 'search', label: t('common:footer.navigation.clients.search'), href: `${pathUrl}/search` },
      ],
    },
    {
      label: t('common:footer.navigation.agencies.label_V2'),
      id: 'agencies',
      links: [
        {
          id: 'agencyHiw',
          label: t('common:footer.navigation.agencies.howItWorks'),
          href: getUrl('providers', { ref: 'footer-apply' }),
        },
        { id: 'pricing', label: t('common:footer.navigation.agencies.pricing'), href: getUrl('providers.pricing') },
        {
          id: 'agencies',
          label: t('common:footer.navigation.agencies.apply'),
          href: getUrl('agency.claim.landing'),
        },
      ],
    },
    {
      label: t('common:footer.navigation.resources.label'),
      id: 'resources',
      links: [
        domainInfo?.hasBlog()
          ? {
              id: 'blog',
              label: t('common:footer.navigation.resources.blog'),
              href: getExternalResourcesUrl('blog', domainInfo, locale),
            }
          : null,
        domainInfo?.hasDataHub()
          ? {
              id: 'dataHub',
              label: t('common:footer.navigation.resources.dataHub'),
              href: getExternalResourcesUrl('datahub', domainInfo, locale),
            }
          : null,
        {
          id: 'helpandsupport',
          label: t('common:footer.navigation.resources.helpAndSupport'),
          href: `https://help.sortlist.com/${locale}`,
        },
      ],
    },
    {
      label: t('common:footer.navigation.company.label'),
      id: 'company',
      links: [
        {
          id: 'about',
          label: t('common:footer.navigation.company.about'),
          href: getUrl('info.about'),
        },
        { id: 'contact', label: t('common:footer.navigation.company.contact'), href: getUrl('info.contact') },
        { id: 'jobs', label: t('common:footer.navigation.company.jobs'), href: getUrl('info.jobs') },
      ],
    },
  ];

  return (
    <>
      {menus.map((menu) => {
        return (
          <div
            data-testid={`footer-menu-${menu.id}-container`}
            key={menu.label}
            className="flex layout-column px-gt-sm-16 px-8 pb-32 lh-3">
            <span className="bold">{menu.label}</span>
            <ul className="list-reset">
              {menu.links.map((link: CustomLink | null) => {
                return link ? (
                  <li key={link.label}>
                    {link.id === 'clientsProjectCTA' ? (
                      <S.BriefingButtonStyled page={briefingOptions?.page} cta={'footer'} label={link.label} />
                    ) : (
                      <Obfuscate
                        href={link.href}
                        className="text-secondary-700 display-block"
                        obfuscate={link.id !== 'blog'}>
                        {link.label}
                      </Obfuscate>
                    )}
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
};
