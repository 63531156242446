import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { usePublicAppContext } from '_core/context/public-app-context';

import * as S from './styles';

export const Others = () => {
  const { t } = useTranslation(['common']);
  const { locale, getUrl } = usePublicAppContext();

  return (
    <ul
      data-testid="footer-bottom-links-container"
      className="small lh-3 p-8 p-gt-sm-16 layout-column layout-gt-sm-row layout-wrap list-reset my-16 text-secondary-700">
      <S.ExtraLink className="text-secondary-700 layout-row">
        <span>{new Date().getFullYear()} © Sortlist&nbsp;-&nbsp;</span>
        <span className="text-secondary-700">{t('common:footer.navigation.extra.right')}</span>
      </S.ExtraLink>

      <S.ExtraLink>
        <Obfuscate className="text-secondary-700" href={getUrl('legal.terms')} obfuscate={true}>
          {t('common:footer.navigation.extra.terms')}
        </Obfuscate>
      </S.ExtraLink>

      <S.ExtraLink>
        <Obfuscate className="text-secondary-700" href={getUrl('legal.privacyPolicy')} obfuscate={true}>
          {t('common:footer.navigation.extra.privacy')}
        </Obfuscate>
      </S.ExtraLink>

      {locale === 'de' && (
        <S.ExtraLink>
          <Obfuscate className="text-secondary-700" href={getUrl('legal.impressum')} obfuscate={true}>
            {t('common:footer.navigation.extra.impressum')}
          </Obfuscate>
        </S.ExtraLink>
      )}
    </ul>
  );
};
