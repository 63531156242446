import { ArrowDropDownRounded, Language } from '@mui/icons-material';
import { Button, Logo, useMediaQuery } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import dynamic from 'next/dynamic';
import { MouseEvent, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';

import type { DesktopDropdownWrapperProps } from './DomainsDropdown/DesktopWrapper';
import type { MobileDropdownWrapperProps } from './DomainsDropdown/MobileWrapper';
import { Menus } from './Menus';
import { Others } from './Others';
import { Socials } from './Socials';
import * as S from './styles';

const DesktopDropdownWrapper = dynamic<DesktopDropdownWrapperProps>(
  () => import('./DomainsDropdown/DesktopWrapper').then((mod) => mod.DesktopDropdownWrapper),
  {
    ssr: false,
  },
);
const MobileDropdownWrapper = dynamic<MobileDropdownWrapperProps>(
  () => import('./DomainsDropdown/MobileWrapper').then((mod) => mod.MobileDropdownWrapper),
  {
    ssr: false,
  },
);

export const Footer = () => {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { domainInfo, locale } = usePublicAppContext();
  const { media } = useMediaQuery();
  const [menu, setMenu] = useState(false);

  const iso31661 = domainInfo?.getIso31661();
  const currentLanguage = domainInfo?.getLanguages()?.filter((lang) => lang.locale === locale)[0];

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (media.gtSm) {
      setAnchorEl(event.currentTarget);
    } else {
      setMenu(!menu);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <footer id="footer" className="bg-secondary-100 p-16 pt-40 width-100">
      <div className="container-lg px-0">
        <div className="layout-column">
          <div className="px-gt-sm-16 px-8">
            <Logo className="mb-64" inversion="negative" height={32} />
          </div>
          <div className="layout-gt-sm-row">
            <S.DescriptionAndSocialsWrapper
              data-testid="footer-description-container"
              className="px-gt-sm-16 px-8 pr-64">
              <p className="bold lh-2">{t('common:footer.descriptionTitle')}</p>
              <p className="lh-2 text-secondary-700">{t('common:footer.description_V2')}</p>
              <Socials />
            </S.DescriptionAndSocialsWrapper>

            <Menus />
          </div>
          <div className="layout-column layout-gt-sm-row layout-align-gt-sm-space-between-center">
            <div className="flex-order-gt-sm-1">
              <Button
                size={'sm'}
                className="width-xs-100 width-sm-100 mt-16 mt-gt-sm-0 flex text-left"
                buttonStyle={'secondary'}
                buttonVariant={'raised'}
                iconLeft={<Language />}
                iconRight={<ArrowDropDownRounded style={{ fontSize: 24 }} />}
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                label={`${currentLanguage?.language}${iso31661 ? ` (${iso31661})` : ''}`}
                id="dropdown-domain-selector-btn"
                onClick={handleOpenMenu}
              />
              <DesktopDropdownWrapper
                className="hide show-gt-sm"
                handleCloseMenu={handleCloseMenu}
                anchorEl={anchorEl}
              />
              <MobileDropdownWrapper className="hide-gt-sm" opened={menu} toggleMenu={handleOpenMenu} />
            </div>
            <Others />
          </div>
        </div>
      </div>
    </footer>
  );
};
