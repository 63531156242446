import styled from '@emotion/styled';
import { Button, COLORS } from '@sortlist-frontend/design-system';
import Image from 'next/image';

export const DescriptionAndSocialsWrapper = styled.div`
  max-width: 25rem;
`;

export const Logo = styled.img`
  width: 6.25rem;
  height: 1.875rem;
`;

export const ExtraLink = styled.li`
  :not(:last-child):not(:first-of-type)::after {
    content: ' - ';
  }
`;

export const MenuItem = styled.li`
  a:hover {
    color: ${COLORS.PRIMARY_500};
    background: ${COLORS.PRIMARY_100};
  }
`;

export const Menu = styled.div`
  opacity: 1;
  z-index: 1;
  min-width: 12rem;
  max-height: 20rem;
  overflow: auto;
  position: absolute;
  bottom: 24px;

  ul:first-of-type {
    border-right-width: 1px;
    border-right-style: solid;
  }
`;

export const MenuButton = styled.span`
  cursor: pointer;
  svg {
    height: 14px;
    width: 14px;
  }
`;

export const BriefCTA = styled.span`
  cursor: pointer;
`;

export const SocialIcon = styled(Image)`
  opacity: 0.65;
`;
