import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';

export const BriefingButtonStyled = styled(BriefingButton)`
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: ${COLORS.SECONDARY_700} !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: normal !important;
`;
