import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

export const ExtraLink = styled.li`
  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    :not(:last-child)::after {
      content: ' - ';
    }
  }
`;
